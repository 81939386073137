
// Margins PC

$eight: clamp(4px,1.86912vw,16px)
$sixteen: clamp(8px,0.83328vw,32px)
$thirtyTwo: clamp(16px,1.66656vw,64px)
$sixtyFour: clamp(32px,3.33312vw,128px)
$oneTwoEight: clamp(64px,6.66624vw,256px)
$twoFiveSix: clamp(128px,13.33248vw,512px)

// Margins Mob

$eightMob: clamp(4px,1.86912vw,16px)
$sixteenMob: clamp(8px,3.73824vw,32px)
$thirtyTwoMob: clamp(16px,7.47648vw,64px)
$sixtyFourMob: clamp(32px,14.95296vw,128px)
$oneTwoEightMob: clamp(64px,29.90592vw,256px)
$twoFiveSixMob: clamp(128px,59.81184vw,512px)

// Colors

$white: #FFF
$gray: #EDEDED
$darkGreen: #032727
$black: #000
$green: #017369

$containerMob: 90.02433090024331%
$container: 77.36111111111111%

// Fonts

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap')

$m24pxMob: clamp(12px,5.60736vw,48px)
$m18pxMob: clamp(9px,4.20552vw,36px)
$m14pxMob: clamp(7px,3.2709599999999996vw,28px)
$m13pxMob: clamp(6.5px,3.03732vw,26px)
$m12pxMob: clamp(6px,2.80368vw,24px)

$p24px: clamp(12px,1.24992vw,48px)
$p18px: clamp(9px,0.93744vw,36px)
$p14px: clamp(7px,0.72912vw,28px)
$p13px: clamp(6.5px,0.67704vw,26px)
$p12px: clamp(6px,0.62496vw,24px)

*
    margin: 0
    padding: 0
    box-sizing: border-box

    .contacts-screen
        width: 100%
        display: flex
        flex-direction: column
        align-items: center
        justify-content: center

        .contacts-section
            width: $containerMob
            display: flex
            flex-direction: column
            // align-items: center
            margin-top: $thirtyTwoMob

            .contacts-heading
                font-family: 'Poppins', sans-serif
                font-size: $m24pxMob
                font-style: normal
                font-weight: 700
                line-height: normal

            .contacts-p
                color: $black
                font-family: 'Poppins', sans-serif
                font-size: $m14pxMob
                font-style: normal
                font-weight: 400
                line-height: normal
                margin-top: $sixteenMob

            .send-contacts
                width: 100%
                display: flex
                flex-direction: column
                align-items: start
                height: clamp(107px,49.99896vw,428px)
                border-radius: 15px
                background: linear-gradient(180deg, #1E55B3 0%, #002565 100%)
                margin-top: $sixteenMob
                margin-bottom: $thirtyTwoMob

                .contact-us-heading
                    color: #FFF
                    font-family: 'Poppins', sans-serif
                    font-size: $m18pxMob
                    font-style: normal
                    font-weight: 700
                    line-height: normal
                    margin-top: $sixteenMob
                    margin-left: $sixteenMob

                .c-us-p
                    width: 94%
                    color: #FFF
                    font-family: 'Poppins', sans-serif
                    margin-top: $sixteenMob
                    margin-left: $sixteenMob
                    font-style: normal
                    font-weight: 300
                    line-height: normal

                .input-container
                    width: clamp(171px,79.90487999999999vw,684px)
                    height: clamp(24px,11.21472vw,96px)
                    border-radius: 8px
                    display: flex
                    align-items: center
                    margin: auto
                    background-color: $white

                    .email-input
                        width: auto
                        border: none
                        outline: none
                        margin-left: $sixteenMob

                    .send-button
                        width: clamp(59px,27.569519999999997vw,236px)
                        height: clamp(18.5px,8.64468vw,74px)
                        border: none
                        border-radius: 4px
                        color: $white
                        font-size: $m14pxMob
                        font-weight: 700
                        background-color: $green
                        margin-left: clamp(12.5px,5.840999999999999vw,50px)

        .web-contacts-section
            display: none

@media screen and  (min-width: 1200px)

    *

        .contacts-section
            display: none !important

        .web-contacts-section
            width: $container
            display: flex !important
            flex-direction: column
            margin-top: $sixtyFour

            .contacts-heading
                font-size: $thirtyTwo !important

            .contacts-p
                font-size: $sixteen

            .contacts-form
                width: 100%
                background: linear-gradient(180deg, #1E55B3 0%, #002565 100%)
                height: auto
                display: flex
                align-items: center
                justify-content: space-between
                border-radius: 15px
                margin-top: $thirtyTwo
                margin-bottom: $sixtyFour

                .text-container
                    display: flex
                    flex-direction: column
                    margin-left: $sixtyFour
                    margin-top: $thirtyTwo
                    margin-bottom: $thirtyTwo

                    .contact-us-heading
                        font-family: 'Poppins', sans-serif
                        font-size: clamp(14px,1.45824vw,56px)
                        color: $white
                        font-style: normal
                        font-weight: 700
                        line-height: normal

                    .contact-p
                        width: 505px
                        color: $white
                        font-family: 'Poppins', sans-serif
                        font-style: normal
                        font-weight: 400
                        line-height: normal
                        font-size: clamp(8.5px,0.88536vw,34px)

                .input-container
                    width: clamp(190px,19.7904vw,760px)
                    height: clamp(24px,2.49984vw,96px)
                    border-radius: 8px
                    background-color: #fff
                    display: flex
                    align-items: center
                    justify-content: space-between
                    margin-right: $sixtyFour

                    .input
                        width: 60%
                        outline: none
                        border: none
                        color: #898989
                        font-family: 'Poppins', sans-serif
                        font-size: 18px
                        font-style: normal
                        font-weight: 400
                        line-height: normal
                        margin-left: clamp(3.5px,0.36456vw,14px)

                    .send-btn
                        width: clamp(61px,6.35376vw,244px)
                        height: clamp(18.5px,1.92696vw,74px)
                        background-color: #1E55B3
                        border: none
                        outline: none
                        color: $white
                        text-align: center
                        font-family: 'Poppins', sans-serif
                        font-size: $sixteen
                        margin-right: clamp(3.5px,0.36456vw,14px)
                        font-style: normal
                        font-weight: 700
                        line-height: normal
                        border-radius: 4px
