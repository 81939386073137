@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
* .header {
  width: 100%;
  height: auto;
  box-shadow: 0px -2px 8px 0px rgba(0, 0, 0, 0.25);
}
* .header .mobile-header {
  width: 90.0243309002%;
  margin: auto;
  display: flex;
  align-items: center;
  margin-top: clamp(16px, 7.47648vw, 64px);
  margin-bottom: clamp(8px, 3.73824vw, 32px);
}
* .header .mobile-header .logo {
  width: clamp(45px, 21.0276vw, 180px);
  height: clamp(20px, 9.3456vw, 80px);
}
* .header .web-header {
  display: none !important;
}

@media screen and (min-width: 1200px) {
  .header {
    width: 77.3611111111%;
    display: flex !important;
    box-shadow: none !important;
    background-color: rgb(255, 255, 255);
  }
  .header .mobile-header {
    display: none;
  }
  .header .web-header {
    width: 100%;
    margin-top: clamp(16px, 1.66656vw, 64px);
    display: flex !important;
    align-items: center;
    justify-content: space-between;
  }
  .header .web-header .logo {
    width: clamp(110px, 11.4576vw, 440px);
    height: clamp(38.5px, 4.01016vw, 154px);
  }
  .header .web-header .navigation-links {
    width: auto;
    display: flex;
    align-items: center;
    margin: auto;
  }
  .header .web-header .navigation-links .menu-item {
    color: #1E55B3;
    font-family: "Poppins", sans-serif;
    text-decoration: none;
    font-size: clamp(8px, 0.83328vw, 32px);
    font-style: normal;
    font-weight: 400;
  }
  .header .web-header .navigation-links .menu-item:active {
    color: #1E55B3;
  }
  .header .web-header .navigation-links .t {
    margin-left: clamp(16px, 1.66656vw, 64px);
  }
  .header .web-header .phone-container {
    display: flex;
    align-items: center;
  }
  .header .web-header .phone-container .phone {
    color: #1E55B3;
    font-family: "Poppins", sans-serif;
    text-decoration: none;
    font-size: clamp(8px, 0.83328vw, 32px);
    font-style: normal;
    font-weight: 400;
  }
  .header .web-header .phone-container .phone-icon {
    color: #1E55B3;
    margin-right: clamp(4px, 1.86912vw, 16px);
  }
}/*# sourceMappingURL=heading.css.map */