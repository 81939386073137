@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
* .footer {
  width: 100%;
  height: auto;
  background-color: #001D4E;
  display: flex;
  flex-direction: column;
  align-items: center;
}
* .footer .footer-phone {
  display: flex;
  flex-direction: column;
  align-items: center;
}
* .footer .footer-phone .logo-white {
  width: clamp(80px, 37.3824vw, 320px);
  height: clamp(40px, 18.6912vw, 160px);
  margin-top: clamp(16px, 7.47648vw, 64px);
}
* .footer .footer-phone .nav-links {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-top: clamp(16px, 7.47648vw, 64px);
}
* .footer .footer-phone .nav-links .link {
  color: #FFF;
  font-family: "Poppins", sans-serif;
  font-size: clamp(8px, 3.73824vw, 32px);
  font-style: normal;
  font-weight: 600;
  margin-top: clamp(8px, 3.73824vw, 32px);
}
* .footer .footer-phone .nav-links .link .main {
  margin-top: clamp(8px, 3.73824vw, 32px);
}
* .footer .footer-phone .line {
  width: 100%;
  height: 1px;
  background-color: #FFF;
  margin-top: clamp(16px, 7.47648vw, 64px);
}
* .footer .footer-phone .footer-paragraph {
  color: #FFF;
  font-family: "Poppins", sans-serif;
  font-size: clamp(6px, 2.80368vw, 24px);
  margin-top: clamp(8px, 3.73824vw, 32px);
  margin-bottom: clamp(8px, 3.73824vw, 32px);
}
* .footer .footer-web {
  display: none;
}
* .footer .line-w {
  display: none;
}
* .footer .footer-paragraph-w {
  display: none;
}

@media screen and (min-width: 1200px) {
  * .footer-phone {
    display: none !important;
  }
  * .footer-web {
    width: 77.3611111111%;
    display: flex !important;
    flex-direction: column;
  }
  * .footer-web .footer-content {
    display: flex;
    align-items: center;
    margin-top: clamp(16px, 1.66656vw, 64px);
  }
  * .footer-web .footer-content .vitamax-logo {
    width: clamp(90px, 9.3744vw, 360px);
    height: clamp(51px, 5.31216vw, 204px);
  }
  * .footer-web .footer-content .nav-links {
    width: auto;
    display: flex;
    justify-content: space-between;
    margin-left: clamp(165px, 17.1864vw, 660px);
  }
  * .footer-web .footer-content .nav-links .link {
    color: #FFF;
    font-size: clamp(8px, 0.83328vw, 32px);
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 600;
    margin-left: clamp(16px, 1.66656vw, 64px);
  }
  * .footer-web .footer-content .nav-links .license {
    margin-right: clamp(16px, 1.66656vw, 64px);
  }
  * .line-w {
    display: flex !important;
    width: 100%;
    height: 1px;
    background-color: #FFF;
    margin-top: clamp(16px, 1.66656vw, 64px);
  }
  * .footer-paragraph-w {
    display: flex !important;
    flex-direction: column;
  }
  * .footer-paragraph-w .footer-paragraph-w-p {
    color: #FFF;
    font-family: Poppins;
    display: flex !important;
    font-size: clamp(6.5px, 0.67704vw, 26px);
    font-style: normal;
    font-weight: 400;
    margin-top: clamp(8px, 0.83328vw, 32px);
    margin-bottom: clamp(8px, 0.83328vw, 32px);
  }
  * .footer-paragraph-w .spark-studio, * .footer-paragraph-w .text-w {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: clamp(16px, 1.66656vw, 64px);
    margin: auto;
    font-size: clamp(8px, 0.83328vw, 32px);
    color: #FFF;
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 600;
  }
  * .footer-paragraph-w .spark-studio {
    margin-top: clamp(16px, 1.66656vw, 64px);
    margin-bottom: clamp(8px, 0.83328vw, 32px);
  }
  * .footer-paragraph-w .spark-studio .spark {
    width: clamp(35px, 3.6456vw, 140px);
    height: clamp(35px, 3.6456vw, 140px);
    margin-top: clamp(8px, 0.83328vw, 32px);
    margin-left: clamp(10px, 1.0416vw, 40px);
    cursor: pointer;
  }
}/*# sourceMappingURL=footer.css.map */