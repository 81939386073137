@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
* .main-screen {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
* .main-screen .main-content {
  width: 100%;
  height: auto;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #EDEDED 100%);
}
* .main-screen .main-content .container-web {
  display: none;
}
* .main-screen .main-content .container {
  width: 90.0243309002%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
}
* .main-screen .main-content .container .illustration {
  width: clamp(175px, 81.774vw, 700px);
  height: clamp(161.142px, 75.29843376vw, 644.568px);
  margin-top: clamp(16px, 7.47648vw, 64px);
}
* .main-screen .main-content .container .heading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: clamp(8px, 3.73824vw, 32px);
}
* .main-screen .main-content .container .heading-container .heading {
  color: #1E55B3;
  text-align: center;
  flex-direction: column;
}
* .main-screen .main-content .container .heading-container .black {
  color: #000;
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-size: clamp(9px, 4.20552vw, 36px);
  font-style: normal;
  font-weight: 600;
}
* .main-screen .main-content .container .main-paragraph {
  width: 100%;
  color: #000;
  text-align: center;
  font-size: clamp(7px, 3.27096vw, 28px);
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: clamp(5px, 2.3364vw, 20px);
}
* .main-screen .main-content .container .contact-us-btn {
  width: clamp(75px, 35.046vw, 300px);
  height: clamp(20.5px, 9.57924vw, 82px);
  border: none;
  border-radius: 4px;
  background-color: #168D42;
  color: #FFF;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Poppins", sans-serif;
  font-size: clamp(6.5px, 3.03732vw, 26px);
  margin-bottom: clamp(20.5px, 9.57924vw, 82px);
  margin-top: clamp(8px, 3.73824vw, 32px);
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
* .main-screen .about-company {
  width: 77.3611111111%;
  display: flex;
  flex-direction: column;
  align-items: start;
  height: auto;
  flex-shrink: 0;
  border-radius: 10px;
  background-color: #FFF;
  margin-top: clamp(-22.5px, -10.5138vw, -90px);
}
* .main-screen .about-company .about-heading {
  margin-top: clamp(9px, 4.20552vw, 36px);
  margin-left: clamp(9px, 4.20552vw, 36px);
  color: #168D42;
}
* .main-screen .about-company .about-p {
  width: clamp(135.5px, 63.31644vw, 542px);
  margin-top: clamp(9px, 4.20552vw, 36px);
  margin-left: clamp(9px, 4.20552vw, 36px);
}
* .main-screen .about-company .about-p .vitamax {
  color: #1E55B3;
  font-weight: 600;
}
* .main-screen .about-company .about-p-2, * .main-screen .about-company .about-p-3 {
  margin-top: clamp(9px, 4.20552vw, 36px);
  margin-left: clamp(9px, 4.20552vw, 36px);
}
* .main-screen .about-company .about-p-2 .green, * .main-screen .about-company .about-p-3 .green {
  color: #1E55B3;
}

@media screen and (min-width: 1200px) {
  .main-screen {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .main-screen .main-content {
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #EDEDED 100%);
  }
  .main-screen .main-content .container {
    display: none;
  }
  .main-screen .main-content .container-web {
    width: 77.3611111111%;
    height: 100%;
    display: flex;
    align-items: center;
    margin: auto;
    margin-top: clamp(37.5px, 3.906vw, 150px);
    margin-bottom: clamp(64px, 6.66624vw, 256px);
  }
  .main-screen .main-content .container-web .content-section {
    width: 100%;
    display: flex;
    align-items: start;
    flex-direction: column;
    margin-top: clamp(45px, 4.6872vw, 180px);
  }
  .main-screen .main-content .container-web .content-section .vitamax {
    color: #168D42;
    font-family: "Poppins", sans-serif;
    font-size: clamp(12px, 1.24992vw, 48px);
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .main-screen .main-content .container-web .content-section .main-heading {
    width: clamp(450px, 46.872vw, 1800px);
    text-align: left !important;
    color: #000;
    font-family: "Poppins", sans-serif;
    font-size: clamp(24px, 2.49984vw, 96px);
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-top: clamp(8px, 0.83328vw, 32px);
  }
  .main-screen .main-content .container-web .content-section .main-paragraph {
    width: clamp(350px, 36.456vw, 1400px);
    font-family: "Poppins", sans-serif;
    font-size: clamp(9px, 0.93744vw, 36px);
    font-style: normal;
    font-weight: 500;
    margin-top: clamp(8px, 0.83328vw, 32px);
  }
  .main-screen .main-content .container-web .content-section .contact-us-btn {
    width: clamp(110px, 11.4576vw, 440px);
    height: clamp(26.5px, 2.76024vw, 106px);
    background-color: #168D42;
    color: #FFF;
    text-align: center;
    font-family: "Poppins", sans-serif;
    font-size: clamp(8px, 0.83328vw, 32px);
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    border-radius: 5px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-top: clamp(16px, 1.66656vw, 64px);
  }
  .main-screen .main-content .container-web .illustration-two {
    width: clamp(375px, 39.06vw, 1500px);
    height: clamp(275px, 28.644vw, 1100px);
    padding-top: 30px;
  }
  .main-screen .about-company {
    width: 77.3611111111%;
    display: flex;
    flex-direction: column;
    align-items: start;
    height: auto;
    flex-shrink: 0;
    border-radius: 20px;
    background: #FFF;
    box-shadow: 0px 8px 25px 0px rgba(129, 189, 149, 0.15);
    background-color: #FFF;
    margin-top: clamp(-75px, -7.812vw, -300px);
  }
  .main-screen .about-company .about-heading {
    font-size: clamp(16px, 1.66656vw, 64px);
    margin-top: clamp(32px, 3.33312vw, 128px);
    margin-left: clamp(32px, 3.33312vw, 128px);
    color: #168D42;
  }
  .main-screen .about-company .about-p {
    font-size: clamp(9px, 0.93744vw, 36px);
    width: auto;
    margin-top: clamp(16px, 1.66656vw, 64px);
    margin-left: clamp(32px, 3.33312vw, 128px);
  }
  .main-screen .about-company .about-p .vitamax {
    color: #1E55B3;
    font-size: clamp(9px, 0.93744vw, 36px);
    font-weight: 600;
  }
  .main-screen .about-company .about-p-2, .main-screen .about-company .about-p-3 {
    width: clamp(550px, 57.288vw, 2200px);
    margin-top: clamp(16px, 1.66656vw, 64px);
    font-size: clamp(9px, 0.93744vw, 36px);
    margin-left: clamp(32px, 3.33312vw, 128px);
  }
  .main-screen .about-company .about-p-2 .green, .main-screen .about-company .about-p-3 .green {
    color: #1E55B3;
  }
  .main-screen .about-company .about-p-3 {
    margin-bottom: clamp(32px, 3.33312vw, 128px);
  }
}/*# sourceMappingURL=main-screen.css.map */