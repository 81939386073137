
// Margins PC

$eight: clamp(4px,1.86912vw,16px)
$sixteen: clamp(8px,0.83328vw,32px)
$thirtyTwo: clamp(16px,1.66656vw,64px)
$sixtyFour: clamp(32px,3.33312vw,128px)
$oneTwoEight: clamp(64px,6.66624vw,256px)
$twoFiveSix: clamp(128px,13.33248vw,512px)

// Margins Mob

$eightMob: clamp(4px,1.86912vw,16px)
$sixteenMob: clamp(8px,3.73824vw,32px)
$thirtyTwoMob: clamp(16px,7.47648vw,64px)
$sixtyFourMob: clamp(32px,14.95296vw,128px)
$oneTwoEightMob: clamp(64px,29.90592vw,256px)
$twoFiveSixMob: clamp(128px,59.81184vw,512px)

// Colors

$white: #FFF
$gray: #EDEDED
$darkGreen: #032727
$black: #000
$green: #017369

$blue: #1E55B3
$green: #168D42

$containerMob: 90.02433090024331%
$container: 77.36111111111111%

// Fonts

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap')

$m24pxMob: clamp(12px,5.60736vw,48px)
$m18pxMob: clamp(9px,4.20552vw,36px)
$m14pxMob: clamp(7px,3.2709599999999996vw,28px)
$m13pxMob: clamp(6.5px,3.03732vw,26px)
$m12pxMob: clamp(6px,2.80368vw,24px)

$p24px: clamp(12px,1.24992vw,48px)
$p18px: clamp(9px,0.93744vw,36px)
$p14px: clamp(7px,0.72912vw,28px)
$p13px: clamp(6.5px,0.67704vw,26px)
$p12px: clamp(6px,0.62496vw,24px)

*

    .main-screen
        width: 100%
        display: flex
        flex-direction: column
        align-items: center
        justify-content: center

        .main-content
            width: 100%
            height: auto
            background: linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, #EDEDED 100%)

            .container-web
                display: none

            .container
                width: $containerMob
                display: flex
                flex-direction: column
                align-items: center
                margin: auto

                .illustration
                    width: clamp(175px,81.774vw,700px)
                    height: clamp(161.142px,75.29843376vw,644.568px)
                    margin-top: $thirtyTwoMob

                .heading-container
                    display: flex
                    flex-direction: column
                    align-items: center
                    justify-content: center
                    margin-top: $sixteenMob

                    .heading
                        color: $blue
                        text-align: center
                        flex-direction: column

                    .black
                        color: #000
                        text-align: center
                        font-family: 'Poppins', sans-serif
                        font-size: $m18pxMob
                        font-style: normal
                        font-weight: 600

                .main-paragraph
                    width: 100%
                    color: $black
                    text-align: center
                    font-size: $m14pxMob
                    font-family: 'Poppins', sans-serif
                    font-style: normal
                    font-weight: 500
                    line-height: normal
                    margin-top: clamp(5px,2.3364vw,20px)

                .contact-us-btn
                    width: clamp(75px,35.046vw,300px)
                    height: clamp(20.5px,9.579239999999999vw,82px)
                    border: none
                    border-radius: 4px
                    background-color: $green
                    color: #FFF
                    text-align: center
                    display: flex
                    align-items: center
                    justify-content: center
                    font-family: 'Poppins', sans-serif
                    font-size: $m13pxMob
                    margin-bottom: clamp(20.5px,9.579239999999999vw,82px)
                    margin-top: $sixteenMob
                    font-style: normal
                    font-weight: 700
                    line-height: normal

        .about-company
            width: $container
            display: flex
            flex-direction: column
            align-items: start
            height: auto
            flex-shrink: 0
            border-radius: 10px
            background-color: $white
            margin-top: clamp(-22.5px,-10.5138vw,-90px)

            .about-heading
                margin-top: $m18pxMob
                margin-left: $m18pxMob
                color: $green

            .about-p
                width: clamp(135.5px,63.31643999999999vw,542px)
                margin-top: $m18pxMob
                margin-left: $m18pxMob

                .vitamax
                    color: $blue
                    font-weight: 600

            .about-p-2, .about-p-3
                margin-top: $m18pxMob
                margin-left: $m18pxMob

                .green
                    color: $blue

@media screen and  (min-width: 1200px)

    *

    .main-screen
        width: 100%
        display: flex
        flex-direction: column
        align-items: center
        justify-content: center

        .main-content
            width: 100%
            height: 100%
            background: linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, #EDEDED 100%)

            .container
                display: none

            .container-web
                width: $container
                height: 100%
                display: flex
                align-items: center
                margin: auto
                margin-top: clamp(37.5px,3.906vw,150px)
                margin-bottom: $oneTwoEight

                .content-section
                    width: 100%
                    display: flex
                    align-items: start
                    flex-direction: column
                    margin-top: clamp(45px,4.6872vw,180px)

                    .vitamax
                        color: $green
                        font-family: 'Poppins', sans-serif
                        font-size: $p24px
                        font-style: normal
                        font-weight: 700
                        line-height: normal

                    .main-heading
                        width: clamp(450px,46.872vw,1800px)
                        text-align: left !important
                        color: $black
                        font-family: 'Poppins', sans-serif
                        font-size: clamp(24px,2.49984vw,96px)
                        font-style: normal
                        font-weight: 700
                        line-height: normal
                        margin-top: $sixteen

                    .main-paragraph
                        width: clamp(350px,36.456vw,1400px)
                        font-family: 'Poppins', sans-serif
                        font-size: $p18px
                        font-style: normal
                        font-weight: 500
                        margin-top: $sixteen

                    .contact-us-btn
                        width: clamp(110px,11.457600000000001vw,440px)
                        height: clamp(26.5px,2.76024vw,106px)
                        background-color: $green
                        color: #FFF
                        text-align: center
                        font-family: 'Poppins', sans-serif
                        font-size: $sixteen
                        display: flex
                        align-items: center
                        justify-content: center
                        border: none
                        border-radius: 5px
                        font-style: normal
                        font-weight: 700
                        line-height: normal
                        margin-top: $thirtyTwo

                .illustration-two
                    width: clamp(375px,39.06vw,1500px)
                    height: clamp(275px,28.644000000000002vw,1100px)
                    padding-top: 30px

        .about-company
            width: $container
            display: flex
            flex-direction: column
            align-items: start
            height: auto
            flex-shrink: 0
            border-radius: 20px
            background: #FFF
            box-shadow: 0px 8px 25px 0px rgba(129, 189, 149, 0.15)
            background-color: $white
            margin-top: clamp(-75px,-7.812vw,-300px)

            .about-heading
                font-size: $thirtyTwo
                margin-top: $sixtyFour
                margin-left: $sixtyFour
                color: $green

            .about-p
                font-size: $p18px
                width: auto
                margin-top: $thirtyTwo
                margin-left: $sixtyFour

                .vitamax
                    color: $blue
                    font-size: $p18px
                    font-weight: 600

            .about-p-2, .about-p-3
                width: clamp(550px,57.288000000000004vw,2200px)
                margin-top: $thirtyTwo
                font-size: $p18px
                margin-left: $sixtyFour

                .green
                    color: $blue

            .about-p-3
                margin-bottom: $sixtyFour
