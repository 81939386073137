
// Margins PC

$eight: clamp(4px,1.86912vw,16px)
$sixteen: clamp(8px,0.83328vw,32px)
$thirtyTwo: clamp(16px,1.66656vw,64px)
$sixtyFour: clamp(32px,3.33312vw,128px)
$oneTwoEight: clamp(64px,6.66624vw,256px)
$twoFiveSix: clamp(128px,13.33248vw,512px)

// Margins Mob

$eightMob: clamp(4px,1.86912vw,16px)
$sixteenMob: clamp(8px,3.73824vw,32px)
$thirtyTwoMob: clamp(16px,7.47648vw,64px)
$sixtyFourMob: clamp(32px,14.95296vw,128px)
$oneTwoEightMob: clamp(64px,29.90592vw,256px)
$twoFiveSixMob: clamp(128px,59.81184vw,512px)

// Colors

$white: #FFF
$gray: #EDEDED
$darkGreen: #032727
$black: #000
$green: #017369

$blue: #1E55B3
$green: #168D42

$containerMob: 90.02433090024331%
$container: 77.36111111111111%

// Fonts

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap')

$m24pxMob: clamp(12px,5.60736vw,48px)
$m18pxMob: clamp(9px,4.20552vw,36px)
$m14pxMob: clamp(7px,3.2709599999999996vw,28px)
$m13pxMob: clamp(6.5px,3.03732vw,26px)
$m12pxMob: clamp(6px,2.80368vw,24px)

$p24px: clamp(12px,1.24992vw,48px)
$p18px: clamp(9px,0.93744vw,36px)
$p14px: clamp(7px,0.72912vw,28px)
$p13px: clamp(6.5px,0.67704vw,26px)
$p12px: clamp(6px,0.62496vw,24px)

*
    .consulting-screen
        width: 100%
        display: flex
        flex-direction: column
        align-items: center
        justify-content: center

        .container
            width: $containerMob
            display: flex
            flex-direction: column
            align-items: center
            margin: auto
            margin-top: $sixtyFourMob

            .consulting-image
                width: 100%
                height: clamp(179px,83.64312vw,716px)
                border-radius: 20px

            .consulting-section
                width: 100%
                display: flex
                flex-direction: column
                align-items: start
                margin: auto
                margin-top: $thirtyTwoMob

                .consulting-heading
                    width: clamp(150px,70.092vw,600px)
                    color: $black
                    font-family: 'Poppins', sans-serif
                    font-size: $m24pxMob
                    font-style: normal
                    text-align: left
                    font-weight: 700
                    line-height: normal

                    .green
                        color: $blue
                        font-family: 'Poppins', sans-serif
                        font-size: $m24pxMob
                        font-style: normal
                        text-align: left
                        font-weight: 700
                        line-height: normal

                .marks
                    margin-top: $thirtyTwoMob

                    .mark-container
                        width: 100%
                        height: auto
                        display: flex
                        align-items: center
                        margin-bottom: 10px

                        .mark
                            width: clamp(7.5px,3.5046vw,30px)
                            height: clamp(7.5px,3.5046vw,30px)

                        .mark-text
                            color: #22343D
                            font-family: 'Poppins', sans-serif
                            font-size: $m14pxMob
                            margin-left: $sixteenMob
                            font-style: normal
                            font-weight: 400
                            line-height: 190%

        .web-container
            display: none

@media screen and  (min-width: 1200px)

    *
    .consulting-screen
        width: 100%
        display: flex
        flex-direction: column
        align-items: center
        justify-content: center

        .container
            display: none

        .web-container
            width: $containerMob
            display: flex
            flex-direction: column
            align-items: center
            margin: auto
            margin-top: $sixtyFourMob

            .consulting-section
                width: 100%
                height: auto
                display: flex
                // align-items: center
                justify-content: space-between

                .consulting-heading
                    font-size: $thirtyTwo !important

                    .green
                        font-size: $thirtyTwo !important

                .marks-container
                    display: flex
                    flex-direction: column
                    align-items: start

                    .consulting-heading
                        width: clamp(150px,70.092vw,600px)
                        color: $black
                        font-family: 'Poppins', sans-serif
                        font-size: $m24pxMob
                        font-style: normal
                        text-align: left
                        font-weight: 700
                        line-height: normal

                    .green
                        color: $blue
                        font-family: 'Poppins', sans-serif
                        font-size: $m24pxMob
                        font-style: normal
                        text-align: left
                        font-weight: 700
                        line-height: normal

                    .marks
                        display: flex
                        flex-direction: column
                        margin-top: $thirtyTwo

                        .mark-container
                            display: flex
                            align-items: center
                            margin-top: $sixteen

                            .mark
                                width: clamp(12.5px,1.302vw,50px)
                                height: clamp(12.5px,1.302vw,50px)

                            .mark-text
                                font-family: 'Poppins', sans-serif
                                font-size: $p18px
                                margin-left: $sixteen

                .consulting-image
                    width: clamp(320px,33.3312vw,1280px)
                    height: clamp(322.5px,33.5916vw,1290px)
