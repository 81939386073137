
// Margins PC

$eight: clamp(4px,1.86912vw,16px)
$sixteen: clamp(8px,0.83328vw,32px)
$thirtyTwo: clamp(16px,1.66656vw,64px)
$sixtyFour: clamp(32px,3.33312vw,128px)
$oneTwoEight: clamp(64px,6.66624vw,256px)
$twoFiveSix: clamp(128px,13.33248vw,512px)

// Margins Mob

$eightMob: clamp(4px,1.86912vw,16px)
$sixteenMob: clamp(8px,3.73824vw,32px)
$thirtyTwoMob: clamp(16px,7.47648vw,64px)
$sixtyFourMob: clamp(32px,14.95296vw,128px)
$oneTwoEightMob: clamp(64px,29.90592vw,256px)
$twoFiveSixMob: clamp(128px,59.81184vw,512px)

// Colors

$white: #FFF
$gray: #EDEDED
$darkGreen: #032727
$black: #000
$green: #017369

$blue: #1E55B3
$green: #168D42

$containerMob: 90.02433090024331%
$container: 77.36111111111111%

// Fonts

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap')

$m24pxMob: clamp(12px,5.60736vw,48px)
$m18pxMob: clamp(9px,4.20552vw,36px)
$m14pxMob: clamp(7px,3.2709599999999996vw,28px)
$m13pxMob: clamp(6.5px,3.03732vw,26px)
$m12pxMob: clamp(6px,2.80368vw,24px)

$p24px: clamp(12px,1.24992vw,48px)
$p18px: clamp(9px,0.93744vw,36px)
$p14px: clamp(7px,0.72912vw,28px)
$p13px: clamp(6.5px,0.67704vw,26px)
$p12px: clamp(6px,0.62496vw,24px)

*
    .header
        width: 100%
        height: auto
        box-shadow: 0px -2px 8px 0px rgba(0, 0, 0, 0.25)

        .mobile-header
            width: $containerMob
            margin: auto
            display: flex
            align-items: center
            margin-top: $thirtyTwoMob
            margin-bottom: $sixteenMob

            .logo
                width: clamp(45px,21.0276vw,180px)
                height: clamp(20px,9.3456vw,80px)

        .web-header
            display: none !important

@media screen and  (min-width: 1200px)

    .header
        width: $container
        display: flex !important
        box-shadow: none !important
        background-color: rgba(255, 255, 255, 1)

        .mobile-header
            display: none

        .web-header
            width: 100%
            margin-top: $thirtyTwo
            display: flex !important
            align-items: center
            justify-content: space-between

            .logo
                width: clamp(110px,11.457600000000001vw,440px)
                height: clamp(38.5px,4.01016vw,154px)

            .navigation-links
                width: auto
                display: flex
                align-items: center
                margin: auto

                .menu-item
                    color: $blue
                    font-family: 'Poppins', sans-serif
                    text-decoration: none
                    font-size: $sixteen
                    font-style: normal
                    font-weight: 400

                    &:active
                        color: $blue

                .t
                    margin-left: $thirtyTwo

            .phone-container
                display: flex
                align-items: center

                .phone
                    color: $blue
                    font-family: 'Poppins', sans-serif
                    text-decoration: none
                    font-size: $sixteen
                    font-style: normal
                    font-weight: 400

                .phone-icon
                    color: $blue
                    margin-right: $eight
