@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
* .container {
  width: 90.0243309002%;
  margin: auto;
}
* h1 {
  color: #168D42;
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-size: clamp(12px, 5.60736vw, 48px);
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
* h2 {
  color: #000;
  font-family: "Poppins", sans-serif;
  font-size: clamp(9px, 4.20552vw, 36px);
  font-style: normal;
  font-weight: 600;
  line-height: 187.5%;
}
* p {
  color: #032727;
  font-family: "Poppins", sans-serif;
  font-size: clamp(7px, 3.27096vw, 28px);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
* button, * a {
  cursor: pointer;
}
* .small-button {
  width: clamp(75px, 35.046vw, 300px);
}/*# sourceMappingURL=styles.css.map */