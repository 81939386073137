
// Margins PC

$eight: clamp(4px,1.86912vw,16px)
$sixteen: clamp(8px,0.83328vw,32px)
$thirtyTwo: clamp(16px,1.66656vw,64px)
$sixtyFour: clamp(32px,3.33312vw,128px)
$oneTwoEight: clamp(64px,6.66624vw,256px)
$twoFiveSix: clamp(128px,13.33248vw,512px)

// Margins Mob

$eightMob: clamp(4px,1.86912vw,16px)
$sixteenMob: clamp(8px,3.73824vw,32px)
$thirtyTwoMob: clamp(16px,7.47648vw,64px)
$sixtyFourMob: clamp(32px,14.95296vw,128px)
$oneTwoEightMob: clamp(64px,29.90592vw,256px)
$twoFiveSixMob: clamp(128px,59.81184vw,512px)

// Colors

$white: #FFF
$gray: #EDEDED
$darkGreen: #032727
$black: #000
$green: #017369

$blue: #1E55B3
$green: #168D42

$containerMob: 90.02433090024331%
$container: 77.36111111111111%

// Fonts

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap')

$m24pxMob: clamp(12px,5.60736vw,48px)
$m18pxMob: clamp(9px,4.20552vw,36px)
$m14pxMob: clamp(7px,3.2709599999999996vw,28px)
$m13pxMob: clamp(6.5px,3.03732vw,26px)
$m12pxMob: clamp(6px,2.80368vw,24px)

$p24px: clamp(12px,1.24992vw,48px)
$p18px: clamp(9px,0.93744vw,36px)
$p14px: clamp(7px,0.72912vw,28px)
$p13px: clamp(6.5px,0.67704vw,26px)
$p12px: clamp(6px,0.62496vw,24px)

*
    .competition-screen
        width: 100%
        display: flex
        flex-direction: column
        align-items: center
        justify-content: center

        .container
            width: $containerMob
            display: flex
            flex-direction: column
            align-items: center
            margin: auto

            .competition-screen
                display: flex
                flex-direction: column
                align-items: center

                .competition-heading-container
                    display: flex
                    flex-direction: column
                    align-items: start
                    margin-top: $sixtyFourMob

                    .competition-heading
                        color: $green
                        font-family: 'Poppins', sans-serif
                        font-size: $m24pxMob
                        font-style: normal
                        font-weight: 700
                        line-height: normal

                    .competition-p
                        margin-top: $sixteenMob

                .competition-tabs
                    width: 100%
                    display: flex
                    flex-direction: column
                    align-items: center
                    margin-top: $thirtyTwoMob

                    .competition-tab
                        width: 100%
                        position: relative
                        border-radius: 15px
                        margin-top: clamp(24px,11.21472vw,96px)

                        .icon
                            position: absolute
                            top: clamp(-20px,-9.3456vw,-80px)
                            left: clamp(12.5px,5.840999999999999vw,50px)
                            z-index: 1000

                        .competition-white
                            width: 100%
                            border-radius: 15px
                            box-shadow: 0px 2px 8px 0px rgba(129, 189, 149, 0.25)

                            .tab-text
                                padding-top: $thirtyTwoMob
                                padding-left: $thirtyTwoMob
                                padding-bottom: $sixteenMob

        .web-container
            display: none

@media screen and  (min-width: 1200px)

    *

    .competition-screen
        width: 100%
        display: flex
        flex-direction: column
        align-items: center
        justify-content: center

        .container
            display: none

        .web-container
            width: $container
            display: flex
            flex-direction: column
            align-items: center
            margin: auto
            margin-top: $oneTwoEight

            .competition-heading-container
                display: flex
                flex-direction: column
                align-items: start

                .competition-heading
                    color: $green
                    font-family: 'Poppins', sans-serif
                    font-size: $thirtyTwo
                    font-style: normal
                    font-weight: 700
                    line-height: normal

                .competition-p
                    font-size: $p18px
                    margin-top: $thirtyTwo

            .competition-tabs
                width: 100%
                display: flex
                flex-direction: column

                .competition-tabs-upper, .competition-tabs-lower
                    width: 100%
                    display: flex
                    margin-top: $oneTwoEight
                    align-items: center
                    justify-content: space-between

                    .competition-tab
                        width: clamp(175px,18.228vw,700px)
                        height: auto
                        border-radius: 15px
                        background: #FFF
                        box-shadow: 0px 2px 8px 0px rgba(129, 189, 149, 0.25)

                        .icon
                            width: clamp(40px,4.1664vw,160px)
                            height: clamp(40px,4.1664vw,160px)
                            margin-top: clamp(-50px,-5.208vw,-200px)
                            margin-left: $thirtyTwo

                        .competition-white
                            display: flex
                            align-items: center
                            justify-content: center
                            margin-bottom: $sixteen

                            .tab-text
                                width: clamp(125px,13.02vw,500px)
                                font-size: clamp(9.5px,0.9895200000000001vw,38px)
                                margin-top: $thirtyTwo
                                margin-bottom: $sixteen

                .competition-tabs-lower

                    .competition-tab
                        width: clamp(175px,18.228vw,700px)
                        height: clamp(110px,11.457600000000001vw,440px)

                .giant-tab
                    width: 100%
                    display: flex
                    margin-top: $oneTwoEight

                    .competition-tab
                        width: 100%
                        height: clamp(68.5px,7.13496vw,274px)
                        border-radius: 15px
                        background-color: $white
                        box-shadow: 0px 2px 8px 0px rgba(129, 189, 149, 0.25)

                        .icon
                            width: clamp(40px,4.1664vw,160px)
                            height: clamp(40px,4.1664vw,160px)
                            margin-top: clamp(-50px,-5.208vw,-200px)
                            margin-left: $thirtyTwo

                        .competition-white
                            display: flex
                            align-items: start
                            justify-content: start
                            margin-bottom: $sixteen

                            .tab-text
                                width: 80%
                                text-align-last: left
                                font-size: clamp(9.5px,0.9895200000000001vw,38px)
                                margin-top: $thirtyTwo
                                margin-bottom: $sixteen
                                margin-left: $thirtyTwo
