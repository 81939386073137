@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
* .competition-screen {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
* .competition-screen .container {
  width: 90.0243309002%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
}
* .competition-screen .container .competition-screen {
  display: flex;
  flex-direction: column;
  align-items: center;
}
* .competition-screen .container .competition-screen .competition-heading-container {
  display: flex;
  flex-direction: column;
  align-items: start;
  margin-top: clamp(32px, 14.95296vw, 128px);
}
* .competition-screen .container .competition-screen .competition-heading-container .competition-heading {
  color: #168D42;
  font-family: "Poppins", sans-serif;
  font-size: clamp(12px, 5.60736vw, 48px);
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
* .competition-screen .container .competition-screen .competition-heading-container .competition-p {
  margin-top: clamp(8px, 3.73824vw, 32px);
}
* .competition-screen .container .competition-screen .competition-tabs {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: clamp(16px, 7.47648vw, 64px);
}
* .competition-screen .container .competition-screen .competition-tabs .competition-tab {
  width: 100%;
  position: relative;
  border-radius: 15px;
  margin-top: clamp(24px, 11.21472vw, 96px);
}
* .competition-screen .container .competition-screen .competition-tabs .competition-tab .icon {
  position: absolute;
  top: clamp(-20px, -9.3456vw, -80px);
  left: clamp(12.5px, 5.841vw, 50px);
  z-index: 1000;
}
* .competition-screen .container .competition-screen .competition-tabs .competition-tab .competition-white {
  width: 100%;
  border-radius: 15px;
  box-shadow: 0px 2px 8px 0px rgba(129, 189, 149, 0.25);
}
* .competition-screen .container .competition-screen .competition-tabs .competition-tab .competition-white .tab-text {
  padding-top: clamp(16px, 7.47648vw, 64px);
  padding-left: clamp(16px, 7.47648vw, 64px);
  padding-bottom: clamp(8px, 3.73824vw, 32px);
}
* .competition-screen .web-container {
  display: none;
}

@media screen and (min-width: 1200px) {
  .competition-screen {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .competition-screen .container {
    display: none;
  }
  .competition-screen .web-container {
    width: 77.3611111111%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
    margin-top: clamp(64px, 6.66624vw, 256px);
  }
  .competition-screen .web-container .competition-heading-container {
    display: flex;
    flex-direction: column;
    align-items: start;
  }
  .competition-screen .web-container .competition-heading-container .competition-heading {
    color: #168D42;
    font-family: "Poppins", sans-serif;
    font-size: clamp(16px, 1.66656vw, 64px);
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .competition-screen .web-container .competition-heading-container .competition-p {
    font-size: clamp(9px, 0.93744vw, 36px);
    margin-top: clamp(16px, 1.66656vw, 64px);
  }
  .competition-screen .web-container .competition-tabs {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .competition-screen .web-container .competition-tabs .competition-tabs-upper, .competition-screen .web-container .competition-tabs .competition-tabs-lower {
    width: 100%;
    display: flex;
    margin-top: clamp(64px, 6.66624vw, 256px);
    align-items: center;
    justify-content: space-between;
  }
  .competition-screen .web-container .competition-tabs .competition-tabs-upper .competition-tab, .competition-screen .web-container .competition-tabs .competition-tabs-lower .competition-tab {
    width: clamp(175px, 18.228vw, 700px);
    height: auto;
    border-radius: 15px;
    background: #FFF;
    box-shadow: 0px 2px 8px 0px rgba(129, 189, 149, 0.25);
  }
  .competition-screen .web-container .competition-tabs .competition-tabs-upper .competition-tab .icon, .competition-screen .web-container .competition-tabs .competition-tabs-lower .competition-tab .icon {
    width: clamp(40px, 4.1664vw, 160px);
    height: clamp(40px, 4.1664vw, 160px);
    margin-top: clamp(-50px, -5.208vw, -200px);
    margin-left: clamp(16px, 1.66656vw, 64px);
  }
  .competition-screen .web-container .competition-tabs .competition-tabs-upper .competition-tab .competition-white, .competition-screen .web-container .competition-tabs .competition-tabs-lower .competition-tab .competition-white {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: clamp(8px, 0.83328vw, 32px);
  }
  .competition-screen .web-container .competition-tabs .competition-tabs-upper .competition-tab .competition-white .tab-text, .competition-screen .web-container .competition-tabs .competition-tabs-lower .competition-tab .competition-white .tab-text {
    width: clamp(125px, 13.02vw, 500px);
    font-size: clamp(9.5px, 0.98952vw, 38px);
    margin-top: clamp(16px, 1.66656vw, 64px);
    margin-bottom: clamp(8px, 0.83328vw, 32px);
  }
  .competition-screen .web-container .competition-tabs .competition-tabs-lower .competition-tab {
    width: clamp(175px, 18.228vw, 700px);
    height: clamp(110px, 11.4576vw, 440px);
  }
  .competition-screen .web-container .competition-tabs .giant-tab {
    width: 100%;
    display: flex;
    margin-top: clamp(64px, 6.66624vw, 256px);
  }
  .competition-screen .web-container .competition-tabs .giant-tab .competition-tab {
    width: 100%;
    height: clamp(68.5px, 7.13496vw, 274px);
    border-radius: 15px;
    background-color: #FFF;
    box-shadow: 0px 2px 8px 0px rgba(129, 189, 149, 0.25);
  }
  .competition-screen .web-container .competition-tabs .giant-tab .competition-tab .icon {
    width: clamp(40px, 4.1664vw, 160px);
    height: clamp(40px, 4.1664vw, 160px);
    margin-top: clamp(-50px, -5.208vw, -200px);
    margin-left: clamp(16px, 1.66656vw, 64px);
  }
  .competition-screen .web-container .competition-tabs .giant-tab .competition-tab .competition-white {
    display: flex;
    align-items: start;
    justify-content: start;
    margin-bottom: clamp(8px, 0.83328vw, 32px);
  }
  .competition-screen .web-container .competition-tabs .giant-tab .competition-tab .competition-white .tab-text {
    width: 80%;
    -moz-text-align-last: left;
         text-align-last: left;
    font-size: clamp(9.5px, 0.98952vw, 38px);
    margin-top: clamp(16px, 1.66656vw, 64px);
    margin-bottom: clamp(8px, 0.83328vw, 32px);
    margin-left: clamp(16px, 1.66656vw, 64px);
  }
}/*# sourceMappingURL=competition-screen.css.map */