@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
* .contacts-screen {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
* .contacts-screen .contacts-section {
  width: 90.0243309002%;
  display: flex;
  flex-direction: column;
  margin-top: clamp(16px, 7.47648vw, 64px);
}
* .contacts-screen .contacts-section .contacts-heading {
  font-family: "Poppins", sans-serif;
  font-size: clamp(12px, 5.60736vw, 48px);
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
* .contacts-screen .contacts-section .contacts-p {
  color: #000;
  font-family: "Poppins", sans-serif;
  font-size: clamp(7px, 3.27096vw, 28px);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: clamp(8px, 3.73824vw, 32px);
}
* .contacts-screen .contacts-section .send-contacts {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
  height: clamp(107px, 49.99896vw, 428px);
  border-radius: 15px;
  background: linear-gradient(180deg, #1E55B3 0%, #002565 100%);
  margin-top: clamp(8px, 3.73824vw, 32px);
  margin-bottom: clamp(16px, 7.47648vw, 64px);
}
* .contacts-screen .contacts-section .send-contacts .contact-us-heading {
  color: #FFF;
  font-family: "Poppins", sans-serif;
  font-size: clamp(9px, 4.20552vw, 36px);
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: clamp(8px, 3.73824vw, 32px);
  margin-left: clamp(8px, 3.73824vw, 32px);
}
* .contacts-screen .contacts-section .send-contacts .c-us-p {
  width: 94%;
  color: #FFF;
  font-family: "Poppins", sans-serif;
  margin-top: clamp(8px, 3.73824vw, 32px);
  margin-left: clamp(8px, 3.73824vw, 32px);
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}
* .contacts-screen .contacts-section .send-contacts .input-container {
  width: clamp(171px, 79.90488vw, 684px);
  height: clamp(24px, 11.21472vw, 96px);
  border-radius: 8px;
  display: flex;
  align-items: center;
  margin: auto;
  background-color: #FFF;
}
* .contacts-screen .contacts-section .send-contacts .input-container .email-input {
  width: auto;
  border: none;
  outline: none;
  margin-left: clamp(8px, 3.73824vw, 32px);
}
* .contacts-screen .contacts-section .send-contacts .input-container .send-button {
  width: clamp(59px, 27.56952vw, 236px);
  height: clamp(18.5px, 8.64468vw, 74px);
  border: none;
  border-radius: 4px;
  color: #FFF;
  font-size: clamp(7px, 3.27096vw, 28px);
  font-weight: 700;
  background-color: #017369;
  margin-left: clamp(12.5px, 5.841vw, 50px);
}
* .contacts-screen .web-contacts-section {
  display: none;
}

@media screen and (min-width: 1200px) {
  * .contacts-section {
    display: none !important;
  }
  * .web-contacts-section {
    width: 77.3611111111%;
    display: flex !important;
    flex-direction: column;
    margin-top: clamp(32px, 3.33312vw, 128px);
  }
  * .web-contacts-section .contacts-heading {
    font-size: clamp(16px, 1.66656vw, 64px) !important;
  }
  * .web-contacts-section .contacts-p {
    font-size: clamp(8px, 0.83328vw, 32px);
  }
  * .web-contacts-section .contacts-form {
    width: 100%;
    background: linear-gradient(180deg, #1E55B3 0%, #002565 100%);
    height: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 15px;
    margin-top: clamp(16px, 1.66656vw, 64px);
    margin-bottom: clamp(32px, 3.33312vw, 128px);
  }
  * .web-contacts-section .contacts-form .text-container {
    display: flex;
    flex-direction: column;
    margin-left: clamp(32px, 3.33312vw, 128px);
    margin-top: clamp(16px, 1.66656vw, 64px);
    margin-bottom: clamp(16px, 1.66656vw, 64px);
  }
  * .web-contacts-section .contacts-form .text-container .contact-us-heading {
    font-family: "Poppins", sans-serif;
    font-size: clamp(14px, 1.45824vw, 56px);
    color: #FFF;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  * .web-contacts-section .contacts-form .text-container .contact-p {
    width: 505px;
    color: #FFF;
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    font-size: clamp(8.5px, 0.88536vw, 34px);
  }
  * .web-contacts-section .contacts-form .input-container {
    width: clamp(190px, 19.7904vw, 760px);
    height: clamp(24px, 2.49984vw, 96px);
    border-radius: 8px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-right: clamp(32px, 3.33312vw, 128px);
  }
  * .web-contacts-section .contacts-form .input-container .input {
    width: 60%;
    outline: none;
    border: none;
    color: #898989;
    font-family: "Poppins", sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-left: clamp(3.5px, 0.36456vw, 14px);
  }
  * .web-contacts-section .contacts-form .input-container .send-btn {
    width: clamp(61px, 6.35376vw, 244px);
    height: clamp(18.5px, 1.92696vw, 74px);
    background-color: #1E55B3;
    border: none;
    outline: none;
    color: #FFF;
    text-align: center;
    font-family: "Poppins", sans-serif;
    font-size: clamp(8px, 0.83328vw, 32px);
    margin-right: clamp(3.5px, 0.36456vw, 14px);
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    border-radius: 4px;
  }
}/*# sourceMappingURL=contacts-screen.css.map */