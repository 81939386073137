@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
* .partners-screen {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #EDEDED;
  margin-top: clamp(32px, 14.95296vw, 128px);
}
* .partners-screen .partners-section {
  width: 90.0243309002%;
  display: flex;
  flex-direction: column;
  margin: auto;
}
* .partners-screen .partners-section .partners-heading {
  font-size: clamp(12px, 5.60736vw, 48px);
  padding-top: 20px;
  padding-left: 20px;
}
* .partners-screen .partners-section .logos {
  width: 100%;
  display: flex;
  flex-direction: column;
}
* .partners-screen .partners-section .logos .logo-upper, * .partners-screen .partners-section .logos .logo-lower {
  width: clamp(155px, 72.4284vw, 620px);
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: auto;
}
* .partners-screen .partners-section .logos .logo-upper {
  margin-top: clamp(16px, 7.47648vw, 64px);
}
* .partners-screen .partners-section .logos .logo-lower {
  margin-top: clamp(16px, 7.47648vw, 64px);
  margin-bottom: clamp(8px, 3.73824vw, 32px);
}
* .partners-screen .partners-section-web {
  display: none;
}

@media screen and (min-width: 1200px) {
  * .partners-screen {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #EDEDED;
    margin-top: clamp(32px, 14.95296vw, 128px);
  }
  * .partners-screen .partners-section {
    display: none;
  }
  * .partners-screen .partners-section-web {
    width: 77.3611111111%;
    display: flex;
    flex-direction: column;
  }
  * .partners-screen .partners-section-web .partners-heading {
    font-size: clamp(16px, 1.66656vw, 64px);
    margin-top: clamp(16px, 1.66656vw, 64px);
  }
  * .partners-screen .partners-section-web .logos {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: clamp(64px, 6.66624vw, 256px);
    margin-top: clamp(32px, 3.33312vw, 128px);
  }
  * .partners-screen .partners-section-web .logos .logo1 {
    width: clamp(96.5px, 10.05144vw, 386px);
    height: clamp(46px, 4.79136vw, 184px);
    cursor: pointer;
  }
  * .partners-screen .partners-section-web .logos .logo2 {
    width: clamp(139.5px, 14.53032vw, 558px);
    height: clamp(30px, 3.1248vw, 120px);
    cursor: pointer;
  }
  * .partners-screen .partners-section-web .logos .logo3 {
    width: clamp(113px, 11.77008vw, 452px);
    height: clamp(63px, 6.56208vw, 252px);
    cursor: pointer;
  }
  * .partners-screen .partners-section-web .logos .logo4 {
    width: clamp(88px, 9.16608vw, 352px);
    height: clamp(55px, 5.7288vw, 220px);
  }
  * .partners-screen .partners-section-web .logos .logo5 {
    width: clamp(83.5px, 8.69736vw, 334px);
    height: clamp(25px, 2.604vw, 100px);
  }
}/*# sourceMappingURL=partners-screen.css.map */