@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
* .team-screen {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
* .team-screen .web-container {
  display: none;
}
* .team-screen .container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: clamp(16px, 7.47648vw, 64px);
}
* .team-screen .container .team {
  width: clamp(138.46px, 64.6995888vw, 553.84px);
  height: clamp(188.5px, 88.08228vw, 754px);
}
* .team-screen .container .team-section {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: start;
  margin-top: clamp(16px, 7.47648vw, 64px);
}
* .team-screen .container .team-section .team-heading {
  color: #168D42;
  font-family: "Poppins", sans-serif;
  font-size: clamp(12px, 5.60736vw, 48px);
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
* .team-screen .container .team-section .team-p {
  margin-top: clamp(8px, 3.73824vw, 32px);
}
* .team-screen .container .team-section .article {
  width: 100%;
  background-color: #FFF;
  border-radius: 4px;
  margin-top: clamp(8px, 3.73824vw, 32px);
}
* .team-screen .container .team-section .article .text {
  font-size: clamp(6px, 2.80368vw, 24px);
  margin: auto;
  padding-top: clamp(5px, 2.3364vw, 20px);
  box-shadow: 0px 2px 8px 0px rgba(129, 189, 149, 0.25);
  padding-bottom: clamp(5px, 2.3364vw, 20px);
}
* .team-screen .container .team-section .article .text .green {
  color: #1E55B3;
}
* .team-screen .container .team-members {
  width: 100%;
  display: flex;
  flex-direction: column;
}
* .team-screen .container .team-members .team-upper, * .team-screen .container .team-members .team-lower {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-top: clamp(16px, 7.47648vw, 64px);
}
* .team-screen .container .team-members .team-upper .team-member-card, * .team-screen .container .team-members .team-lower .team-member-card {
  width: 100%;
  height: clamp(97.5px, 45.5598vw, 390px);
  border-radius: 15px;
  display: flex;
  cursor: pointer;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 2px 8px 0px rgba(129, 189, 149, 0.25);
}
* .team-screen .container .team-members .team-upper .team-member-card .avatar, * .team-screen .container .team-members .team-lower .team-member-card .avatar {
  width: clamp(40px, 18.6912vw, 160px);
  height: clamp(40px, 18.6912vw, 160px);
  border-radius: 100px;
}
* .team-screen .container .team-members .team-upper .team-member-card .name-heading, * .team-screen .container .team-members .team-lower .team-member-card .name-heading {
  margin-top: clamp(12.5px, 5.841vw, 50px);
  font-size: clamp(8px, 3.73824vw, 32px);
  margin: auto;
  text-align: center;
  font-weight: 700;
}
* .team-screen .container .team-members .team-upper .team-member-card .role, * .team-screen .container .team-members .team-lower .team-member-card .role {
  color: #8E8E8E;
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  font-style: normal;
  margin: auto;
  text-align: center;
  font-weight: 500;
  line-height: normal;
}
* .team-screen .container .team-members .team-member-description {
  margin-top: clamp(16px, 7.47648vw, 64px);
}
* .team-screen .container .team-members .team-member-description .name-heading {
  color: #168D42;
  font-family: "Poppins", sans-serif;
  font-size: clamp(12px, 5.60736vw, 48px);
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
* .team-screen .container .team-members .team-member-description .team-member-paragraph {
  margin-top: clamp(8px, 3.73824vw, 32px);
}
* .team-screen .container .team-members .team-lower {
  margin-top: clamp(8px, 3.73824vw, 32px);
}

@media screen and (min-width: 1200px) {
  .team-screen {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .team-screen .container {
    display: none;
  }
  .team-screen .web-container {
    width: 77.3611111111%;
    display: flex;
    flex-direction: column;
    margin-top: clamp(64px, 6.66624vw, 256px);
  }
  .team-screen .web-container .team-heading {
    color: #168D42;
    font-family: "Poppins", sans-serif;
    font-size: clamp(16px, 1.66656vw, 64px);
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .team-screen .web-container .team-p {
    width: 70%;
    margin-top: clamp(16px, 1.66656vw, 64px);
    font-size: clamp(9px, 0.93744vw, 36px);
  }
  .team-screen .web-container .tabs-container {
    width: 100%;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .team-screen .web-container .tabs-container .tabs {
    display: flex;
    flex-direction: column;
  }
  .team-screen .web-container .tabs-container .tabs .article {
    width: clamp(365px, 38.0184vw, 1460px);
    height: clamp(69.5px, 7.23912vw, 278px);
    border-radius: 15px;
    background: #FFF;
    box-shadow: 0px 2px 8px 0px rgba(129, 189, 149, 0.25);
    margin-top: clamp(16px, 1.66656vw, 64px);
  }
  .team-screen .web-container .tabs-container .tabs .article .text {
    font-size: clamp(9px, 0.93744vw, 36px);
    margin: auto;
    padding-top: clamp(8px, 0.83328vw, 32px) !important;
    padding-bottom: clamp(8px, 0.83328vw, 32px) !important;
  }
  .team-screen .web-container .tabs-container .tabs .article .green {
    color: #1E55B3;
  }
  .team-screen .web-container .tabs-container .team {
    width: clamp(169px, 17.60304vw, 676px);
    height: clamp(250.5px, 26.09208vw, 1002px);
  }
  .team-screen .web-container .team-members {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: clamp(32px, 3.33312vw, 128px);
  }
  .team-screen .web-container .team-members .team-member-card {
    width: clamp(127.5px, 13.2804vw, 510px);
    height: clamp(150px, 15.624vw, 600px);
    display: flex;
    cursor: pointer;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 15px;
    background: #FFF;
    box-shadow: 0px 2px 8px 0px rgba(129, 189, 149, 0.25);
  }
  .team-screen .web-container .team-members .team-member-card .avatar {
    width: clamp(67px, 6.97872vw, 268px);
    height: clamp(67px, 6.97872vw, 268px);
    border-radius: clamp(50px, 5.208vw, 200px);
  }
  .team-screen .web-container .team-members .team-member-card .name-heading {
    font-family: "Poppins", sans-serif;
    font-size: clamp(10px, 1.0416vw, 40px);
    font-style: normal;
    font-weight: 700;
    text-align: center;
    align-items: center;
    line-height: normal;
    margin-top: clamp(8px, 0.83328vw, 32px);
  }
  .team-screen .web-container .team-members .team-member-card .role {
    color: #8E8E8E;
    font-family: "Poppins", sans-serif;
    font-size: clamp(8px, 0.83328vw, 32px);
    font-style: normal;
    font-weight: 500;
    align-items: center;
    line-height: normal;
    margin: 0 auto;
    text-align: center;
  }
  .team-screen .web-container .team-member-description {
    margin-top: clamp(32px, 3.33312vw, 128px);
  }
  .team-screen .web-container .team-member-description .name-heading {
    color: #168D42;
    font-family: "Poppins", sans-serif;
    font-size: clamp(12px, 1.24992vw, 48px);
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .team-screen .web-container .team-member-description .team-member-paragraph {
    width: 70%;
    margin-top: clamp(8px, 0.83328vw, 32px);
    font-size: clamp(9px, 0.93744vw, 36px);
  }
}/*# sourceMappingURL=team.css.map */