@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
* .documents-screen {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: clamp(16px, 7.47648vw, 64px);
}
* .documents-screen .documents-section {
  width: 90.0243309002%;
  display: flex;
  flex-direction: column;
  margin: auto;
}
* .documents-screen .documents-section .documents-heading {
  color: #168D42;
  font-size: clamp(12px, 5.60736vw, 48px);
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
* .documents-screen .documents-section .documents {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: clamp(10px, 4.6728vw, 40px);
  margin-bottom: clamp(10px, 4.6728vw, 40px);
}
* .documents-screen .documents-section .documents .document {
  width: clamp(58.5px, 27.33588vw, 234px);
  height: clamp(82.5px, 38.5506vw, 330px);
  box-shadow: 0px 2px 8px 0px rgba(129, 189, 149, 0.25);
  cursor: pointer;
}
* .web-documents-section {
  display: none;
}

@media screen and (min-width: 1200px) {
  * .documents-section {
    display: none !important;
  }
  * .web-documents-section {
    width: 77.3611111111%;
    height: auto;
    display: flex;
    flex-direction: column;
  }
  * .web-documents-section .documents-heading {
    color: #168D42;
    font-size: clamp(16px, 1.66656vw, 64px);
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  * .web-documents-section .documents {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: clamp(32px, 3.33312vw, 128px);
  }
  * .web-documents-section .documents .document-one-c, * .web-documents-section .documents .document-two-c, * .web-documents-section .documents .document-three-c {
    display: flex;
    flex-direction: column;
  }
  * .web-documents-section .documents .document-one-c .document, * .web-documents-section .documents .document-two-c .document, * .web-documents-section .documents .document-three-c .document {
    width: clamp(175px, 18.228vw, 700px);
    height: clamp(247.434px, 25.77272544vw, 989.736px);
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.1);
    cursor: pointer;
  }
  * .web-documents-section .documents .document-one-c .text, * .web-documents-section .documents .document-two-c .text, * .web-documents-section .documents .document-three-c .text {
    color: #038B73;
    font-family: "Poppins", sans-serif;
    font-size: clamp(9px, 0.93744vw, 36px);
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-top: clamp(12px, 1.24992vw, 48px);
  }
}/*# sourceMappingURL=document-screen.css.map */